body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  #myTable{
    width: 100%;
    border-spacing: 3px;
    border-collapse: separate;
  }
  #myTable_comparing{
    width: 100%;
    border-spacing: 3px;
    border-collapse: separate;
  }

  #myTable_comparing th{
    background-color: rgb(208, 216, 226);
    width: 30%;
    font-size: 16px;
      font-weight: 600;
      color: #253978; 
  }
  #myTable_comparing td{
      width: 35%; 
      font-size: 16px;
      font-weight: 600;
      color: #253978; 
}
  
  .NavBar{
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
  }
  .nav_header{
    color: white;
    width: 96%;
    height: 100%;
    display: block;
    margin: auto;
  }
  .nav_header_item_div{
    display: grid;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .common_bg_color{
    background-color: #23232c;
  }
  .item_bar{
    color: white;
  }
  .nav_header_item{
    display: flex;
    height: 100%;
    justify-content: space-between;
  
    width: 100%;
  
  }
  .navItems{
    display: flex;
    height: 100%;
    align-items: center;
    grid-gap: 2rem;
  }
  ._navItems{
    display: flex;
    height: 100%;
    align-items: center;
    grid-gap: 6rem;
  }
  .logoImg{
    display: flex;
    height: 100%;
    align-items: center;
    grid-gap: 3rem;
  }
  .profileCircle{
    width: 40px;
    height: 40px;
    background: lightgray;
    border-radius: 100%;
  }
  .profileDetails{
    display: grid;
    text-align: center;
  }
  .sideNav{
    height: 100%;
    width: 80px;
    position: fixed;
    z-index: 1;
    top: 74px;
    left: 0;
    background-color: #3d3d3d;
    overflow-x: hidden;
    
    padding-top: 60px;
  }
  .homeContent{
    width: 98%;
    display: block;
    margin: auto;
    background-color: #eee;
    height: 100vh;
    border: 2px solid red;
  }
  #main{
      transition: margin-left .5s;
      border: 2px solid gray;
      margin: auto;
      display: block;
      margin-left: 85px;
      margin-top: 80px;
      margin-right: 5px;
      margin-bottom: 5px;
      text-align: left;
      padding:2rem;
      height: calc(100vh - 86px);
      overflow: hidden;
      overflow-y: auto;
  
  }
  .vender_Risk_Management_System{
    font-size: 28px;
    font-weight: 700;
  }
  input[type="text" i] {
    padding: revert;
  }
  .SearchBox{
      height: 48px;
      display: grid;
      align-items: center;
      padding-left: 10px !important;
      width: 350px;
      padding-top: 0px !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border: 2px solid #777 ;
      border-right: none;
      font-size: 18px;
      outline: none;
  }
  .SearchBox_new{
      padding: 10px;
      display: grid;
      align-items: center;
      padding-left: 10px !important;
      width: 400px;
      padding-top: 10px !important;
      border-radius: 6px;
      border: 1px solid #d0d8e2 ;
      font-size: 16px;
      outline: none;
  }
  .Search_section{
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .btn_search{
    
    height: 48px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: grid;
    align-items: center;
    padding: 10px;
    padding-top: 8px;
    border-color:#777 ;
    outline: none;
    border-width: 2px;
  }
  .searchBarSections{
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: 60% 40%;
  }
  
  .CardSections{
    margin-top: 2rem;
  }
  .CardBox{
    width: 300px;
    height: 150px;
    border: 2px solid #777;
    border-radius: 10px;
    background-color: #777;
    color: white;
  }
  .CardSections_1{
    display: flex;
    grid-gap: 2rem;
  }
  .CardBox_text{
    text-align: left;
    height: 100%;
    display: grid;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    align-content: center;
    width: 90%;
    margin: auto;
  }
  .CardBox_text_{
    font-size: 20px;
  }
  .Number_of_Venders{
    background-color: aqua;
  }
  .CardBox_1{
    width: 300px;
    height: 150px;
    border: 2px solid #eee;
    border-radius: 10px;
    background-color: #eee;
    color: #777;
  }
  .PBIReport{
    margin-top: 4rem;
  }
  
  .IfreamCls{
    width: 100%;
    height: 750px;
    border: 2px solid #777;
    padding: 1rem;
    border-radius: 5px;
  }
  
  .ProfileInfo{
    display: grid;
  }
  
  td,th {
   
    padding: 1rem;
  }
  
  td{
    font-weight: 700;
  }
  
  tr,td,th{
    border: 2px solid #eee;
  }
  table{
    
  }
  .Basic_Informations{
    font-size: 20px;
    margin-top: 2rem;
    color: #253978;
    font-weight: 600;
    border-radius: 3px;
    
  }
  .BasicInfoSections{
    margin-top: 1rem;
    color: #777;
  }
  .hideElement {
    display: none;
  }
  th{
    color: #111;
  }
  td{
    color: #777;
  }
  .dec_text{
    border: 2px solid #eee;
    padding: 1rem;
    border-radius: 6px;
    margin-top: 0rem;
  }
  .ContactInfo{
    display: grid;
    width: 100%;
    margin-top: 0rem;
  }
  #Contect_info td{
    text-align: left;
    padding-left: 1rem;
    padding-right: 2rem;
   
  }
  .ActiveContact{
    
    display: grid;
  }
  .Active_contact{
    margin-top: 0rem;
    display: grid;
  }
  
  .login_body_content {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .loginPage {
    background-color: aqua;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  
  }
    
  .textAlign {
    text-align: left;
  }
  
  .MarsLogo {
    justify-content: center;
    align-content: center;
    width: 100%;
    display: grid;
  }
  
  .login_info {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .bgColor{
    background-color: #23232c !important;
  }
  .activeHeader{
    width: 100%;
  }
  
  .leftTable{
    float: left;
    border: none;
  }
  .rightTable{
    float: right;
    border: none;
  }
  .leftTable_1{
    
    border: none;
  }
  .rightTable_1{
   
    border: none;
  }
  .btn_comm{
    background-color: #777;
    padding: 1rem;
    color: white;
    border-radius: 40px;
  }
  
  #ActiveContact th{
    width: fit-content;
  }
  #Technical_Informations th{
    width: fit-content;
  }
  .sideNavMenu{
    width: 90%;
    display: block;
    margin: auto;
  }
  .sideNavMenu_grid{
    width: 100%;
    display: grid;
    grid-gap: 15px;
    justify-content: center;
    justify-items: center;
  }
 
  .item_menuText{
    display: none;
  }
  .Search_bar{
    width: 100%;
    height: 100px;
    background-color: #253978;
    color: white;
  }
  .tech_border_right{
    border-right: 0px !important ;
  }
  .tech_border_left{
    border-left:0px ;
    text-align: right;
  }

  .charts_diagram{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: baseline;
    grid-gap: 1.5rem;
    justify-content: center;
  }
  
  .listItem_name{
    grid-gap: 10px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 6px;
    border: 0px solid #eee;
    padding: 2.5rem;
    display: grid;
    align-items: center;
    background-color: #eee;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .error_message{
    text-align: center;
    height: 500px;
    width: 100%;
    display: grid;
    align-content: center;
    font-size: 24px;
    font-weight: 600;
  }
  .Click_here{
    color: blue;
  }
  
  #myTable1{
    width: 1000px;
    border-collapse: separate;
    border-spacing: 10px;

  }
  .table_class{
    width: auto !important;
  }

  .Userlisting{
    grid-template-columns: repeat(3,30%);
    display: grid;
    grid-gap: 20px;
  }
  
  
  
  .Userlisting_items a:hover{
    background: transparent !important;
  }
  a:hover{
    background: transparent !important;
  }
  
  .pathDisplay{
    display: flex;
    grid-gap: 10px;
  }
  
  #NoDataAvialable{
    display: none;
  }
  .Not_found{
    padding: 14px;
  }

  .Print_btn{
    width: 100%;
    padding: 10px;
    padding-left: 3rem;
    padding-right: 3rem;
    border: none;
    background-color: #253978;
    color: white;
    border-radius: 6px;

  }
  .Search_sectionnew{
    display: none !important;
  }
  
   .RiskAnalysis_sections{
    display: grid;
    grid-template-columns: repeat(3,30%);
    grid-gap:3rem;
    justify-content: left;

   }
   .Security_Rating_Score{
    padding-bottom: 20px;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
   }
   
  

   .CardBox_details {
    width: auto;
    height: 255px;
    border: 0px solid #777;
    border-radius: 10px;
    background-color: transparent;
    color: black;
}

.CardBox_details_1 {
  width: auto;
  height: auto;
  border: 0px solid #777;
  border-radius: 10px;
  background-color: white;
  color: white;
  display: grid;
  justify-content: center;
  padding: 10px;
  padding-bottom: 20px;
  padding-top: 30px;
}

.items_card_risk{
  border: 2px solid #777;
  border-radius: 10px;
}
.items_card_risk_1{
  background-color: #eee;
}

.speedometer{
  width: 100% !important;
  height: 100% !important;
}
.btn_Print{
  /*padding-left: 3.6rem;
  padding-right: 3.6rem;
  height: 40px;
  display: grid;
  align-items: center;
  border-radius: 8px;*/
  background-color: transparent;
  color: #777;
  border: none;
}
.Print_flex{
    height: 100%;
    display: flex;
    grid-gap: 10px;
    align-items: center;
}

.List_users_page{
  display: grid;
  grid-gap: 10px;
}

.float_right{
  float: right;
}
.MutipleSeclections_div{
  position: relative;
}
#MultiCheckbox_div{
  width: auto;
  height: auto;
  background-color: white;
  position: absolute;
  right: 3px;
  display: none;
  box-shadow: 0px 10px 34px rgba(0, 0, 0,0.9);
  border-radius: 5px;
  margin-top: 5px;
  color: #777;
  font-weight: 600;
  min-width: 220px;
  max-width: 230px;
}



#checkboxes{
  display: grid;
  grid-gap: 0px;
}
#checkboxes label{
 padding: 18px;
 display: flex;
 grid-gap: 10px;
 font-size: 14px;
 align-items: center;
 height: 100%;
 border: 1px solid #eee;
 border-top: none;
 
}
.item_input{
  padding-right: 10px;
}
.Print_rep{
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 700;
  color: #253978;
  background: transparent;
  border: none;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #0000a0;
}




.log_pannel{
  color: #777;
  position: absolute;
  top: 80px;
  right: 30px;
  background-color: #eee;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #777;
  z-index: -1;
  width: auto;
  padding: 5px;
  padding-left:2rem ;
  padding-right: 2rem;


}


.loading_screen{
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  align-content: center;
  background-color: rgba(0, 0, 0,0.2);
}
.loading_alignment{
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  align-content: center;
}

.logoutCss {
  position: absolute;
  bottom: 113px;
  cursor: pointer;
}
.das_services{
  font-weight: 700;
  color: #777;
}
.login_bg_image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.text_header_color{
  color: #3d3d3d;
}
.searchLoading{
  position: absolute;
    display: grid;
    justify-content: center;
    width: 100%;
}
  
.tooltip {
  position: relative;
  display: inline-block;
  opacity: inherit;
  cursor: pointer;

}

.grid_body .tooltiptext {
  display: none;
  width: 120px;
  background-color: #e0e0e0;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  color: #777;
  padding: 7px 0;
  width: 120px;
  z-index: 1;
  top: 28px;
  font-size: 12px;
}

.grid_body .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  left: 10%;
  border-color: transparent transparent #e0e0e0 transparent;
}
.grid_body:hover .tooltiptext {
  display: block;
}
.flexbody{
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
  grid-gap: 10px;
}
.grid_body{
  display: grid;
  cursor: pointer;
  position: relative;
}

.infoCircleico{
  color: #e0e0e0;
}

table{
  border-spacing: 3px;
  border-collapse: separate;
}
.text_align_center{
  text-align: center !important;
}

.novendorFound{
    text-align: center;
    display: grid;
    height: 100%;
    align-items: center;
    align-content: center;
    font-size: 16px;
    color: #0000a0;
}
.novendorFound_1{
  height: 100%;
    display: grid;
    align-items: center;
    align-content: center;
}

.PlaseWait{
  grid-gap: 10px;
  align-content: baseline;
  color: #0000a0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  text-align: center;
  align-items: center;
}
.spinner_class{
  display:grid;
  justify-content: center;
}
.noVender{
  color: red;
}

.Charts_items{
  border: 1px solid rgb(247, 249, 252);
  padding: 10px;
  border-radius: 9px;
  background: rgb(247, 249, 252);
}

.pagination{
  margin: 10px;
}



.rounded-checkbox {
  width:18px;
  height: 18px;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #eee;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(45% at 50% 50%);
  background-color: blue;
}
.label_by_search{
    font-size: 14px;
    padding-left: 10px;
    font-weight: 500;
    color: #3d3d3d;
}

.risk_div{
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 10px;

}
.items_risk_div{
  width: 100%;
  background: #fff;
  border: 1px solid #d0d8e2;
  border-radius: 4px;
  margin-bottom: 10px;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  padding: 22px 20px;
  cursor: pointer;

}
.items_risk_div_1{
  width: 100%;
  background: #fff;
  border: 0px solid #d0d8e2;
  border-radius: 4px;
  margin-bottom: 10px;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  padding: 22px 20px;
  cursor: pointer;
}



.vendor_risk_items_des{
  width: 100%;
  background-color: transparent;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 30% 15% 10% 25% 15% 5% ;

}
.vendor_risk_items_cls{
  padding: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #eee;
  border-radius: 5px;
}

.vendor_risk_items_low{
  padding: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #c0e5c0;
  border-radius: 5px;
}
.vendor_risk_items_high{
  padding: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #e3a9a9;
  border-radius: 5px;
}

.vendor_risk_items_critical{
  padding: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #FFCCCB;
  border-radius: 5px;
}
.vendor_risk_items_veryLow{
  padding: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: #8ee18e;
  border-radius: 5px;
}
.vendor_risk_items_modrate{
  padding: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: rgba(255,165,0,0.2);
  border-radius: 5px;
}

.vendor_risk{
  font-size: 14px;
  font-weight: 600;
  color: #253978;

}
.vendor_risk_items_cls,.vendor_loc{
  color: #253978;

}
.itemsFlex{
  display: flex;
    grid-gap: 10px;
    height: 100%;
    align-content: center;
    align-items: center;
}

.custom-select{
  border: 1px solid #d0d8e2;
    padding: 10px;
    border-radius: 10px;
    color: #777;
}

.flex_box_div{
  box-sizing: border-box;
  display: grid;
  flex-flow: row wrap;
  width: calc(100%);
  grid-gap: 10px;
}

.no_of_vendors_text {
  margin: 0px 0px 24px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
color: #3d3d3d;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.no_of_vendors_values {
  margin: 0px 0px 24px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
  text-align: left;
  color: #3d3d3d;

  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.LastWeek_text {
  margin: 0px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.57;
  color: rgba(55, 111, 208, 0.85);
  text-align: left;
}

.cardBox_itmes_grid {
  padding: 16px;
  position: relative;
  display: grid;
  width: 100%;
}
.css-1k2gklx{
  height: 120px;
    right: 4px;
    bottom: 4px;
}
.card_box_new_div{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: rgb(50 50 93 / 2%) 0px 2px 5px -1px, rgb(0 0 0 / 5%) 0px 1px 3px -1px;
    position: relative;
    background: #f5f5f5;
    color: rgb(55, 111, 208);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}
.annualItmes{
    display: grid;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
    font-size: 12px;
    background-color: #3d3d3d;
    color: rgb(255, 255, 255);

}
.negativeCss{
    color: rgb(244, 67, 54);
    font-weight: 600;
    background: rgba(244, 67, 54, 0.1);
    padding: 5px;
    border-radius: 3px;
    margin-right: 8px;
    padding-top: 2px;

}
.negativeCssIcons{
  color: rgb(244, 67, 54);
}
.SinceLastWeekicons{
  color:#43a047;
}
.SinceLastWeekNew{
  
  color: #43a047;
  font-weight: 600;
  background: rgba(80, 222, 85, 0.1);
  background: #b0ebb0;
  padding: 5px;
  border-radius: 3px;
  margin-right: 8px;
  padding-top: 2px;
}
.SinceLastWeek{
  color: #3d3d3d;
  font-weight: 600;
  background: rgba(26, 27, 26, 0.1);
  padding: 5px;
  border-radius: 3px;
  margin-right: 8px;
  padding-top: 4px;
}

.GraphText{
  padding: 22px;
    padding-bottom: 0px;
    font-size: 20px;
    font-weight: 700;
    color: #253978;
}
.negativeCssRisk{
  color: #3d3d3d;
  font-weight: 600;
  background: rgba(75, 74, 74, 0.1);
  border-radius: 3px;
  padding: 5px 5px 5px;
  font-size: 12px;
}
.Vname_PO{
  padding: 10px;
    font-size: 20px;
    font-weight: 600;
}

.active>.page-link{
  background-color: #0000a0 !important;
  outline: none !important;
  border-color:#0000a0 !important;
  color: white !important;
}
.page-link {
  color: #0000a0 !important;
}
.pagination{
  --bs-pagination-focus-color: transparent !important;
  --bs-pagination-focus-box-shadow: transparent !important;
  --bs-pagination-hover-color: transparent !important;
    --bs-pagination-hover-bg:#eee !important;
}
.vendorComparison{
  display: grid;
  grid-template-columns: repeat(2,49%);
  grid-gap: 10px;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}
.Firstvendor{
  display: grid;
  height: 100%;
  background-color: #eee;
  position: relative;
}
.Secondvendor{
  display: grid;
  height: 100%;
  background-color: #eee;
  position: relative;

}

.form-check {
  padding-left: 33px !important;
  margin: auto !important;
}

.searchNewDivClass{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-right: 20px !important;

}
.paddingStyle{
padding: 4px;
}

.sidePannelFilter{
  background: #e4e9ed;
  border: 2px solid #e4e9ed;
  border-radius: 4px;
  height: calc(100vh - 96px);
  right: 6px;
  position: fixed;
  top: 85px;
  transition: margin-left .5s;
  width: 25%;
  z-index: 999;
  overflow: hidden;
  overflow-y: auto;
}

.filterContent{
  display: none;
  padding-top: 1rem;
}
.floating_div{
  position: fixed;
  bottom: 10px;
  right: 5%;
}
.comparing_item_div{
  display: flex;
    flex-wrap: wrap;
    gap: 33px;
    background: rgb(238, 238, 238);
    padding: 1rem;
    align-items: center;
    box-shadow: 0px 0px 40px;
    border-radius: 10px;
}
.sidePannelFilter{
  display: none;
}

.activeMenu{
  background-color: #eee !important;
  color: #253978 !important;
  border-radius: 4px;
}
.items_menu{
  color: white;
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  font-size: 16px;
  padding: 7px;

}
.activeMenu .items_menu{
  background-color: #eee !important;
  color: #253978 !important;
  border-radius: 4px;
}
.WelcomeBack{  
  font-size: 20px;
  font-weight: 700;
  color: #0000A0;
}
.userinfo{
  font-size: 18px;
  font-weight: 600;
  color: #0000A0;
}


